import { redirect } from "react-router-dom";
import { Toast } from "react-vant";
import { extend } from "umi-request";

const http = extend({
  errorHandler: (error) => {
    console.log(error, error?.type);
    if (error.type === "TypeError") {
      Toast("网络错误");
      return;
    }
    if (!!error.message) {
      if (typeof error.message !== "string") {
        return;
      }
      const data = JSON.parse(error.message);
      if ([401, 403].includes(data.code)) {
        const token = localStorage.getItem("token");
        if (!!token) {
          localStorage.setItem("token", "");
          Toast(data.message);
        } else {
          Toast("请先登录");
        }
        redirect("/login");
      } else {
        Toast(data.message);
      }
      return;
    }
  },
});

http.use(async (ctx, next) => {
  const token = localStorage.getItem("token");
  if (!!token) {
    ctx.req.options.headers = {
      ...ctx.req.options.headers,
      Authorization: token,
    };
  }
  await next();
  if (ctx?.res?.code !== 200) {
    throw new Error(JSON.stringify(ctx?.res));
  }
});

export default http;
