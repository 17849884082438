const isDev = "production" !== "production";

export default {
  isDev,
  name: "拓梦空间",
  apiUrl: "api.theoneart.com.cn",
  tencent: {
    captchaId: "196116308",
  },
};
